/** @jsxRuntime classic */
/** @jsx jsx */
import React, {FC, useContext} from 'react'
import {jsx, Box, Flex} from 'theme-ui'
import { useUI } from '@components/common/context'
import {useRouter} from "next/router"
import ModalContext from "@components/modals/ModalContext"
import Sidebar from "@components/common/Sidebar"
import Link from "@components/common/Link"

interface HeaderProps {
  consultantIsOrange: boolean
}

const Header: FC<HeaderProps> = ({ consultantIsOrange }) => {
  const { navigationLinks, openSidebar, displaySidebar, closeSidebar } = useUI()
  const { asPath } = useRouter()

  const { setShow } = useContext(ModalContext);

  const renderLinks = navigationLinks?.map((item, index: number) => {
    if (!item.link) return null;
    return (
        <Link
            variant={'nav-link'}
            pt={10}
            pb={'5px'}
            itemProp="url"
            href={item.link}
            key={`nav-link-${index}`}
            target={"_self"}
            className="nav-link-hov position-relative"
        >
          <span style={{paddingInline: '10px'}} itemProp="name">{item.title}</span>
          <div className={["nav-link-decoration", (asPath.split('?')[0] === item.link && "nav-link-decoration-active")].join(" ")} />
        </Link>
    )
  });

  return (
    <React.Fragment>
      <Link
          href={'/'}
          bg={'background'}
          className={'logo hide-on-tablet justify-content-center align-items-center d-flex'}
          sx={{left: 0, height: '105px', fontFamily: 'lombok', flexDirection: 'column'}}
          color={'secondary'}
          aria-label="Walls – Главная"
      >
        <svg viewBox="0 5 87 16" width="100%" height={'100%'}>
          <text sx={{fill: 'muted'}} fontSize="34px" textAnchor="middle" y="20" x="50%">
            W<tspan sx={{fill: 'secondary'}} fontSize="20px" >ALLS</tspan>
          </text>
          <text sx={{fontFamily: 'gardensc', fill: 'secondary'}} letterSpacing="2px" fontSize="5px" textAnchor="middle" dy="30" dx="50%">
            БРЕНД&nbsp;&nbsp;ДОМОВ
          </text>
        </svg>
      </Link>
      <Box
          variant={consultantIsOrange ? 'boxes.consultant-secondary':'boxes.consultant'}
          className={'consultant hide-on-tablet justify-content-center align-items-center'}
          sx={{right: 0, height: '100px'}}
          onClick={setShow}
      >
        <svg viewBox="0 5 87 54" width="100%" height={'100%'} fontFamily={'Montserrat,sans-serif'}>
          <text fontSize="14px" textAnchor="middle" y="35" x="50%">
            Консультант
          </text>
        </svg>
      </Box>

      <Box className={'nav-layout hide-on-tablet'}>
        <Flex sx={{justifyContent: 'center'}}>
          <Flex sx={{gap: '10px'}} bg={'secondary'}>{renderLinks}</Flex>
        </Flex>
      </Box>


      <Box className="mobile-header">
        <Link
            href={'/'}
            bg={'background'}
            className={'mobile-header__logo'}
            sx={{fontFamily: 'lombok', flexDirection: 'column'}}
            color={'secondary'}
        >
          <svg viewBox="0 5 100 27" width="100%" height={'100%'}>
            <text sx={{fill: 'muted'}} fontSize="15px" textAnchor="middle" y="20" x="25%">
              W<tspan sx={{fill: 'secondary'}} fontSize="10px">ALLS</tspan>
            </text>
            <text sx={{fontFamily: 'gardensc', fill: 'secondary'}} letterSpacing="2px" fontSize="4px" textAnchor="middle" dy="27" dx="25%">
              БРЕНД ДОМОВ
            </text>
          </svg>
        </Link>
        <Box className="mobile-header__menu-button" onClick={openSidebar}>
          <i className="bi bi-list"/>
        </Box>
      </Box>

      <Sidebar open={displaySidebar} onClose={closeSidebar}>
        <Box sx={{fontFamily: 'Lato, sans-serif'}}>
          {renderLinks}
        </Box>
      </Sidebar>
    </React.Fragment>
  )
}

export default Header
