import {Builder, withChildren} from '@builder.io/react';
import React  from 'react';
import dynamic from "next/dynamic";

const LazySocialButton = dynamic(async () => {
  return (await import('./SocialButton')).SocialButton
})

const SocialButtonBuilderChildren = withChildren(LazySocialButton);

Builder.registerComponent(SocialButtonBuilderChildren,
    {
      name: 'SocialButton',
      canHaveChildren: true,
      inputs: [
        {
          name: 'image',
          type: 'file',
          allowedFileTypes: ['jpeg', 'png', 'gif', 'svg'],
          hideFromUI: true,
          helperText: 'This is an editable region where you can drag and drop blocks.',
          defaultValue: [
            {
              '@type': '@builder.io/sdk:Element',
              component: {
                name: "Image",
                options: {
                  image: "https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2F72c80f114dc149019051b6852a9e3b7a",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  lazy: true,
                  aspectRatio: 1,
                  sizes: "(max-width: 998px) 31vw, 28vw",
                  height: 1300,
                  width: 868,
                }
              },
              "responsiveStyles": {
                "large": {
                  "flexDirection": "column",
                  "position": "relative",
                  "boxSizing": "border-box",
                  "overflow": "hidden",
                  "width": "56px",
                  "marginLeft": "auto",
                  "marginRight": "auto"
                }
              }
            },
          ],
        },
        {
          name: 'title',
          type: 'text',
          hideFromUI: true,
          helperText: 'This is an editable region where you can drag and drop blocks.',
          defaultValue: [
            {
              '@type': '@builder.io/sdk:Element',
              component: {
                name: 'Text',
                options: {
                  text: 'Text...'
                }
              },
              "responsiveStyles": {
                "large": {
                  "fontSize": "10px",
                  "fontWeight": "600"
                },
              },
            },
          ],
        },
        {
          name: 'link',
          type: 'url',
          bubble: true,
        },
        {
          name: 'openLinkInNewTab',
          type: 'boolean',
          defaultValue: false,
          friendlyName: 'Open link in new tab',
        },
      ],

      defaultStyles: {
        "display": "flex",
        "flexDirection": "row",
        "position": "relative",
        "boxSizing": "border-box",
        "marginTop": "0px",
        "marginLeft": "0px",
        "marginRight": "0px",
        "width": "145px",
        "opacity": "1",
        "color": "rgba(255, 255, 255, 1)",
      },
    },
);

