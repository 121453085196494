import { createContext } from "react";

interface IAccordionContext {
  state?: number
  toggleAccordion: (idx: number) => void
}

const AccordionContext = createContext<IAccordionContext>({state: undefined, toggleAccordion: (idx: number) => null});

export default AccordionContext;
