/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import {jsx, Box, Flex, Grid, Text, Button, Spinner} from "theme-ui";
import Link from "@components/common/Link";
import React, {useRef, useState} from "react";
import {IconHeart} from "@components/common/IconHeart";
import {useUI} from "@components/common/context";
import {Form, FormRef} from "@components/common/Form/Form";
import {InputFieldForm} from "@components/common/Form/InputFieldForm";
import {PhoneNumberFieldForm} from "@components/common/Form/PhoneNumberFieldForm";
import {CheckboxFieldForm} from "@components/common/Form/CheckboxFieldForm";
import {CenterModal} from "@components/modals";
import {TextareaFieldForm} from "@components/common/Form/TextareaFieldForm";
import {sendBid} from "@lib/senBid";

export const Footer = () => {
  const { navigationLinks } = useUI()
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)
  const [isFetching, setIsFetching] = useState(false)
  const formRef = useRef<FormRef>(null)

  const request = async (data: any) => {
    setIsFetching(true)
    await sendBid('http://google.com', data, setIsFetching, formRef?.current?.reset)
  }

  const renderLinks = navigationLinks?.map((item, index: number) => {
    if (!item.link || item.link === '/') return null;
    return (
        <Link
            variant={'nav-link-footer'}
            paddingY={'10px'}
            paddingX={'20px'}
            itemProp="url"
            href={item.link}
            key={`footer-nav-link-${index}`}
            target={"_self"}
        >
          <span itemProp="name">{item.title}</span>
        </Link>
    )
  });

  return (
      <>
        <Box bg={'primary'} paddingY={'60px'} paddingX={['20px', '10vw']} color={'secondary'}>
          <Grid pb={'20px'} columns={[1, 1, '2fr 3fr']}>
            <Flex sx={{justifyContent: 'center', alignItems: ['center', null, 'flex-start'], flexDirection: 'column', order: [2, 2, 1]}}>
              <Link
                  href={'/'}
                  sx={{left: 0, maxWidth: '400px', height: '140px', fontFamily: 'lombok', flexDirection: 'column'}}
                  aria-label="Walls – Главная"
              >
                <svg sx={{ fill: 'secondary', opacity: 0.8}} viewBox="0 5 87 16" width="100%" height={'100%'}>
                  <text sx={{translate: ['20%', '20%', 0]}} fontSize="24px" textAnchor="start" y="18" x="-2px">
                    W<tspan fontSize="14px" >ALLS</tspan>
                  </text>
                  <text  sx={{fontFamily: 'gardensc', translate: ['20%', '20%', 0]}} letterSpacing="0px" fontSize="3px" textAnchor="start" dy="23" dx="7%">
                    Б Р Е Н Д&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Д О М О В
                  </text>
                </svg>
              </Link>

              <Flex mt={4} sx={{justifyContent: 'start', fontSize: '14px', maxWidth: '420px'}}>
                <Flex sx={{flexDirection: 'column', textAlign: ['center', null, 'left']}}>
                  <Text pb={2}>
                    Предложение на сайте не является публичной офертой.
                    Актуальную информацию о стоимости домов уточняйте по телефону или в офисе продаж.
                  </Text>
                  <Link variant='underline' color="secondary" sx={{fontWeight: 100, fontFamily: 'Montserrat, sans-serif'}} href="/privacy">Политика конфиденциальности</Link>
                  <Link variant='underline' color="secondary" sx={{fontWeight: 100, fontFamily: 'Montserrat, sans-serif'}} href="/">Настройка cookie</Link>
                </Flex>
              </Flex>
            </Flex>
            <Flex sx={{justifyContent: 'stretch', alignItems: ['center', 'center', 'flex-end'], flexDirection: 'column',  order: [1, 1, 2]}}>

              <Flex sx={{flexDirection: 'column', justifyContent: "space-between", flex: '1 1 auto'}}>
                <Flex pb={'40px'} sx={{justifyContent: ['center', 'center', 'flex-end'], alignItems: 'center', flexWrap: 'wrap'}}>
                  {renderLinks}
                </Flex>

                <Grid paddingX={'20px'} columns={[1, 1, 2]}>

                  <Flex sx={{flexDirection: 'column', fontSize: '20px', justifyContent: 'space-between', alignItems: ['center', 'center', 'flex-start']}}>
                    <Text sx={{fontWeight: 100}}>ОФИС ПРОДАЖ</Text>
                    <Link sx={{fontSize: '32px', lineHeight: '32px', fontFamily: 'Montserrat, sans-serif'}} color={'secondary'} href="tel:+375291111111">+375 29 111 11 11</Link>
                  </Flex>

                  <Flex sx={{flexDirection: 'column', fontSize: '14px', alignItems: ['center', 'center', 'normal']}}>
                    <Text pb={2}>Напишите нам и мы вам поможем</Text>
                    <Button onClick={() => setModalIsOpen(true)} variant={'btn-footer'} sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', stroke: 'muted', gap: '10px'}}>
                      <IconHeart width={'40px'} />
                      <Text sx={{textAlign: "left", lineHeight: '12px', fontWeight: 400}}>Служба заботы<br/> о клиентах</Text>
                    </Button>
                  </Flex>

                </Grid>
              </Flex>

            </Flex>
          </Grid>
          <Box mt={'60px'}>
            <Text sx={{fontSize: '14px', fontWeight: 100}}>© 2025 | ООО "ЭкспоХаус" | WALLS – стройте с умом, живите с комфортом! | Все права защищены | Сайт не является публичной офертой.</Text>
          </Box>
        </Box>
        <CenterModal contentProps={{sx:{backgroundColor: 'primary', color: 'secondary', paddingY: '60px'}}} isOpen={modalIsOpen} onDismiss={() => setModalIsOpen(false)} closeButton>
          <Grid columns={['2fr', '1fr 2fr']} sx={{fontSize: '16px'}}>
            <Flex sx={{color: 'secondary', stroke: 'secondary', justifyContent: 'center', alignItems: 'flex-start', fontSize: '16px', gap: 10, paddingTop: '8px'}}>
              <IconHeart width={'40px'} />
              <Text sx={{textAlign: "left", lineHeight: '14px', fontWeight: 400}}>Служба заботы<br/> о клиентах</Text>
            </Flex>
            <Box>
              <Box sx={{fontSize: ['24px', '34px'], textAlign: ['center', 'left']}}>ОСТАВИТЬ ОТЗЫВ</Box>
              <Form ref={formRef} onSubmit={request}>
                <Grid pt={'40px'} gap={['0px', '0px', 30]} columns={[1, 1, 2]}>
                  <InputFieldForm name="name" variant={'forms.inputs.secondary'} placeholder="Имя" type="name" mb={3} errorMsg={"Укажите имя"} required={true} />
                  <PhoneNumberFieldForm name="phone" variant={'forms.inputs.secondary'} placeholder="Телефон" mb={3} errorMsg={"Укажите телефон"} required={true} />
                </Grid>
                <Flex>
                  <InputFieldForm name="email" variant={'forms.inputs.secondary'} placeholder="Электронная почта" type="email" mb={3} errorMsg={"Укажите email"} required={true} />
                </Flex>
                <Flex>
                  <TextareaFieldForm
                      autoHeight
                      name="review"
                      variant={'forms.inputs.secondary'}
                      placeholder={"Ваш отзыв"}
                      type={"text"}
                      required={true}
                      rows={1}
                      errorMsg={"Оставьте, пожалуйста, вашу обратную связь"}
                      mb={3}
                  />
                </Flex>
                <Box py={'20px'}>
                  <CheckboxFieldForm name={'agreement'} variant={'forms.checkbox.secondary'} required={true} defaultChecked title="Я согласен(а) на обработку персональных данных" />
                </Box>
                <Flex sx={{justifyContent: 'flex-end'}}>
                  <Button variant={'send'} color={'muted'} sx={{cursor: 'pointer', height: '56px'}}>{isFetching ? <Spinner size={36} /> : 'ОТПРАВИТЬ'}</Button>
                </Flex>
              </Form>
            </Box>
          </Grid>
        </CenterModal>
      </>
  )
}
