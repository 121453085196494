import dynamic from "next/dynamic";
import {Builder} from "@builder.io/react";


const LazyYouTubeVideo = dynamic(async () => {
  return (await import('./YouTubeVideo')).YouTubeVideo
})

Builder.registerComponent(LazyYouTubeVideo, {
  name: 'YouTubeVideo',
  inputs: [
    {
      name: 'videoId',
      type: 'string',
    }
  ]
})