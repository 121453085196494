import { createContext } from "react";

interface IModalContext {
  modalShow: boolean
  setShow: () => void
  setHide: () => void
}

const ModalContext = createContext<IModalContext>({modalShow: false, setShow: () => null, setHide: () => null});

export default ModalContext;
