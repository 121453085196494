import {Checkbox} from "theme-ui";
import {Label} from "@theme-ui/components";
import React, {FC} from "react";
import {useFormContext} from "react-hook-form";

interface CheckboxFieldFormProps {
  name: string
  defaultChecked?: boolean
  variant?: string
  title: any
  required?: boolean
}

export const CheckboxFieldForm: FC<CheckboxFieldFormProps> = (props) => {
  const { name, variant, defaultChecked, title, required } = props
  const { register, formState: { errors } } = useFormContext()

  return (
      <Label mb={3} sx={{fontSize: '14px', display: 'flex', alignItems: 'center', ...(errors?.[`${name}`] && {color: 'red'})  }}>
        <Checkbox defaultChecked={defaultChecked} variant={variant} {...register(name, {required})} />
        {title}
      </Label>
  )
}