import { FC } from 'react'
import type { AppProps } from 'next/app'
import Layout from '@components/common/Layout'
import { builder, Builder } from '@builder.io/react'
import builderConfig from '@config/builder'
import localFont from 'next/font/local'

builder.init(builderConfig.apiKey)

const lombok = localFont({
  src: '../public/fonts/Lombok.ttf',
  variable: '--font-lombok',
  display: 'swap',
})

const gardensc = localFont({
  src: '../public/fonts/GardensC.otf',
  variable: '--font-gardensc',
  display: 'swap',
})

// import '@builder.io/widgets'
// import '@builder.io/widgets/dist/lib/builder-widgets-async';

import '../blocks/Chat/Chat.builder'
import '../blocks/ProductsGrid/ProductsGrid.builder'
import '../blocks/YandexMap/YandexMap.builder'
import '../blocks/WriteToUs/WriteToUs.builder'

import '../blocks/AnimatedNumber/AnimatedNumber.builder'
import '../blocks/SocialButton/SocialButton.builder'
import '../blocks/Accordion/Accordion.builder'
import '../blocks/ModalButton/ModalButton.builder'

import './global.scss'
import '../public/slick/slick.css'
import '../public/slick/slick-theme.css'
import 'react-toastify/dist/ReactToastify.css'


import '../blocks/Carousel/Carousel.builder'
import '../blocks/YouTubeVideo/YouTubeVideo.builder'
import '../blocks/DirectionsCard/DirectionsCard.builder'
import '../blocks/Grid/Grid.builder'

Builder.register('insertMenu', {
  name: 'Site',
  items: [
    { name: 'AnimatedNumber' },
    { name: 'SocialButton' },
    { name: 'Accordion' },
    { name: 'ModalButton' },
    { name: 'Chat' },
    { name: 'ProductsGrid' },
    { name: 'YandexMap' },
    { name: 'WriteToUs main page' },
    { name: 'Carousel' },
    { name: 'YouTubeVideo' },
    { name: 'DirectionsCard' },
    { name: 'Grid' }
  ],
})

Builder.register('insertMenu', {
  name: 'Cloudinary Components',
  items: [{ name: 'CloudinaryImage' }],
})


const Noop: FC<{ children: React.ReactNode }> = ({ children }) => (
  <>{children}</>
)

export default function MyApp({ Component, pageProps }: AppProps) {
  return (
    <Layout
        pageProps={pageProps}
        fonts={{
          lombok: lombok.style.fontFamily,
          gardensc: gardensc.style.fontFamily,
          // latoBlack: latoBlack.style.fontFamily,
        }}
    >
      <Component {...pageProps} />
    </Layout>
  )
}
