// @ts-nocheck
import {Box, BoxOwnProps, Input, Textarea} from "theme-ui";
import {Controller, useFormContext} from "react-hook-form";
import React, {FC, useRef} from "react";
import {Label} from "@theme-ui/components";
import {isValidPhoneNumber} from "react-phone-number-input";
import PhoneInput from "react-phone-number-input/react-hook-form-input";

interface TextareaFieldFormProps extends BoxOwnProps{
  name: string
  errorMsg?: string
  required?: boolean
  variant: string
  placeholder?: string
  type?: string
  rows?: number
  autoHeight?: boolean
}

export const TextareaFieldForm:FC<TextareaFieldFormProps> = (props) => {
  const {errorMsg, name, required, autoHeight, ...inputProps} = props
  const textareaRef = useRef<HTMLTextAreaElement>(null)

  const handleInput = () => {
    textareaRef.current.style.minHeight = '41px'
    textareaRef.current.style.minHeight = textareaRef.current.scrollHeight + "px"
  }

  return (
      <Controller
          name={name}
          rules={{
            validate: (value) => required ? value?.length > 5 : true,
            required
          }}
          render={({ field: { onChange, value }, fieldState: {error} }) => (
              <Box sx={{flexGrow: 1, position: "relative"}}>
                <Textarea {...inputProps} ref={textareaRef} onInput={(e) => {
                  autoHeight && handleInput(e)
                }} onChange={onChange} value={value} />
                {error &&
                <Label sx={{position: 'absolute', bottom: 0, fontSize: '12px', color: 'red'}} htmlFor={name}>
                  {errorMsg}
                </Label>
                }
              </Box>
          )}
      />
  )
}