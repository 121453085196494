import dynamic from "next/dynamic";
import {Builder} from "@builder.io/react";

const LazyDirectionsCard = dynamic(async () => {
  return (await import('./DirectionsCard')).DirectionsCard
})

Builder.registerComponent(LazyDirectionsCard, {
  name: 'DirectionsCard',
  noWrap: true,
  inputs: [
    {
      name: 'link',
      type: 'string'
    },
    {
      name: 'title',
      type: 'string'
    },
    {
      name: 'text',
      type: 'richText'
    }
  ]
})