import React from "react";
import {Builder, noWrap} from "@builder.io/react";
import dynamic from "next/dynamic";

const LazyChat = dynamic(async () => {
  return (await import('./Chat')).Chat
})

const ChatBuilderChildren = noWrap(LazyChat)

Builder.registerComponent(ChatBuilderChildren, {
  name: 'Chat',
  inputs: [
    {
      name: 'ChatIcon',
      type: 'file',
      allowedFileTypes: ['jpeg', 'png']
    },
    {
      name: 'chats',
      type: 'list',
      copyOnAdd: false,
      subFields: [
        {
          name: 'chatLink',
          type: 'reference',
          model: 'chat-link'
        },
      ],
      defaultValue: [

      ],
    },
  ]
})
