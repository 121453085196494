import AccordionContext from "./AccordionContext";
import {FC, ReactNode, useState} from "react";

interface Props {
  children: ReactNode
}

const ModalProvider:FC<Props> = ({ children }) => {
  const [state, setState] = useState<number | undefined>(undefined);

  const toggleAccordion = (idx: number) => {
    setState(prevState => prevState === idx ? undefined : idx)
  };

  return (
      <AccordionContext.Provider value={{ state, toggleAccordion }}>
        {children}
      </AccordionContext.Provider>
  );
};

export default ModalProvider;