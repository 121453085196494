// @ts-nocheck
import {Controller} from 'react-hook-form'
import React, {FC} from 'react'
import PhoneInput from "react-phone-number-input/react-hook-form-input";
import {Box, BoxOwnProps, Input} from "theme-ui";
import {Label} from "@theme-ui/components";
import {isValidPhoneNumber} from "react-phone-number-input";

type PhoneNumberFieldFormProps = BoxOwnProps & {
  name: string
  errorMsg?: string
  required?: boolean
  variant: string
  placeholder?: string
  type?: string
}

export const PhoneNumberFieldForm:FC<PhoneNumberFieldFormProps> = (props) => {
  const {errorMsg, name, required, ...inputProps} = props

  return (
      <Controller
          name={name}
          rules={{
            validate: (value) => isValidPhoneNumber(value || '')}}
          render={({ field: { onChange, value }, fieldState: {error} }) => (
              <Box sx={{flexGrow: 1, position: "relative"}}>
                <PhoneInput name={name} inputComponent={Input} {...inputProps} value={value} onChange={onChange}/>
                {error && <Label sx={{position: 'absolute', bottom: 0, fontSize: '12px', color: 'red'}} htmlFor={name}>{errorMsg}</Label>}
              </Box>
          )}
      />
  )
}