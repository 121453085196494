import {Builder, noWrap} from "@builder.io/react";
import dynamic from "next/dynamic";

const LazyYandexMap = dynamic(async () => {
  return (await import('./YandexMap')).YandexMap
})

const YandexMapBuilder = noWrap(LazyYandexMap)

Builder.registerComponent(YandexMapBuilder, {
  name: 'YandexMap',
  noWrap: true,
  inputs: [
    {
      name: 'center',
      type: 'object',
      friendlyName: 'Настройки',
      subFields: [
        {
          name: 'zoom',
          type: 'number',
        },
        {
          name: 'longitude',
          type: 'number',
          friendlyName: 'Широта'
        },
        {
          name: 'latitude',
          type: 'number',
          friendlyName: 'Долгота'
        },
      ],
      defaultValue: {
        zoom: 9,
        latitude: 54.05,
        longitude: 52.05,
      }
    },
    {
      name: 'list',
      type: 'list',
      subFields: [
        {
          name: 'title',
          type: 'string',
        },
        {
          name: 'description',
          type: 'richText',
        },
        {
          name: 'hint',
          type: 'string'
        },
        {
          name: 'longitude',
          type: 'number',
          friendlyName: 'Широта'
        },
        {
          name: 'latitude',
          type: 'number',
          friendlyName: 'Долгота'
        },
        {
          name: 'color',
          type: 'color',
          friendlyName: 'Цвет метки'
        }
      ]
    }
  ]
})
