import dynamic from "next/dynamic";
import {Builder, BuilderElement, noWrap} from "@builder.io/react";

const LazyCarousel = dynamic(async () => {
  return (await import('./Carousel')).Carousel
})

const CarouselBuilderChildren = noWrap(LazyCarousel)

const defaultButton: BuilderElement = {
  '@type': '@builder.io/sdk:Element',
  responsiveStyles: {
    large: {
      position: 'relative',
      fontSize: '40px'
    },
  },
};

const defaultElement: BuilderElement = {
  '@type': '@builder.io/sdk:Element',
  responsiveStyles: {
    large: {
      // TODO: always apply these if not given
      position: 'relative',
      display: 'flex',
      alignItems: 'stretch',
      flexDirection: 'column',
      height: '400px',
    },
  },
  children: [
    {
      '@type': '@builder.io/sdk:Element',
      responsiveStyles: {
        large: {
          marginTop: '50px',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
        },
      },
      component: {
        name: 'Text',
        options: {
          text: 'I am a slide',
        },
      },
    },
  ],
};

Builder.registerComponent(CarouselBuilderChildren, {
  name: 'Carousel',
  canHaveChildren: true,
  inputs: [
    {
      name: 'className',
      type: 'string',
      defaultValue: 'center',
    },
    {
      name: 'centerMode',
      type: 'boolean',
      defaultValue: true,
    },
    {
      name: 'infinite',
      type: 'boolean',
      defaultValue: true
    },
    {
      name: 'centerPadding',
      type: 'string',
      defaultValue: '60px',
    },
    {
      name: 'slidesToShow',
      type: 'number',
      defaultValue: 3,
    },
    {
      name: 'variableWidth',
      type: 'boolean',
      defaultValue: false,
    },
    {
      name: 'autoplay',
      helperText: 'Automatically rotate to the next slide every few seconds',
      type: 'boolean',
      defaultValue: false,
    },
    {
      name: 'autoplaySpeed',
      type: 'number',
      defaultValue: 5,
      helperText: 'If auto play is on, how many seconds to wait before automatically changing each slide',
      // showIf: (options: any) => options.get('autoplay'),
    },

    {
      name: 'responsive',
      type: 'array',
      subFields: [
        {
          name: 'breakpoint',
          type: 'number',
        },
        {
          name: 'settings',
          type: 'object',
          subFields: [
            {
              name: 'slidesToShow',
              type: 'number',
            },
            {
              name: 'className',
              type: 'string'
            },
            {
              name: 'centerMode',
              type: 'boolean'
            },
            {
              name: 'centerPadding',
              type: 'string'
            }
          ]
        }
      ],
      defaultValue: [
        {
          breakpoint: 1600,
          settings: {
            slidesToShow: 2,
            className: "center",
            centerMode: true,
            centerPadding: '60px'
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 1,
            className: "",
            centerMode: false,
            centerPadding: '60px'
          }
        },
      ]
    },
    {
      name: 'prevButton',
      type: 'uiBlocks',
      hideFromUI: true,
      defaultValue: [
        {
          ...defaultButton,
          layerName: 'prevButton',
          responsiveStyles: {
            large: {
              position: "absolute",
              fontSize: "40px",
              left: "35px",
              transform: "translate(0px, -50%)"
            }
          },
          children: [
            {
              '@type': '@builder.io/sdk:Element',
              component: {
                name: 'Text',
                options: {
                  text: '<p class="bi bi-arrow-left-circle" />',
                },
              },
            },
          ]
        },
      ],
    },
    {
      name: 'nextButton',
      type: 'uiBlocks',
      hideFromUI: true,
      defaultValue: [
        {
          ...defaultButton,
          layerName: 'nextButton',
          responsiveStyles: {
            large: {
              position: "absolute",
              fontSize: "40px",
              right: "35px",
              transform: "translate(0px, -50%)"
            }
          },
          children: [
            {
              '@type': '@builder.io/sdk:Element',
              component: {
                name: 'Text',
                options: {
                  text: '<p class="bi bi-arrow-right-circle" />',
                },
              },
            },
          ]
        },
      ],
    },
  ],
  defaultChildren: [
    defaultElement,
    defaultElement,
    defaultElement
  ],
})