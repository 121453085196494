import ModalContext from "@components/modals/ModalContext";
import {FC, ReactNode, useState} from "react";

interface Props {
  children: ReactNode
}

const ModalProvider:FC<Props> = ({ children }) => {
  const [modalShow, setModalShow] = useState(false);


  const setShow = () => {
    setModalShow(true)
  };

  const setHide = () => {
    setModalShow(false)
  };

  return (
      <ModalContext.Provider value={{ modalShow, setShow, setHide }}>
        {children}
      </ModalContext.Provider>
  );
};

export default ModalProvider;