import {Builder, noWrap} from "@builder.io/react";
import dynamic from "next/dynamic";

const LazyWriteToUs = dynamic(async () => {
  return (await import('./WriteToUs')).WriteToUs
})

const WriteToUsBuilder = noWrap(LazyWriteToUs)

Builder.registerComponent(WriteToUsBuilder, {
  name: 'WriteToUs main page',
  inputs: [
    {
      name: 'fields',
      type: 'list',
      copyOnAdd: false,
      subFields: [
        {
          name: 'name',
          type: 'string',
          required: true,
          defaultValue: false
        },
        {
          name: 'type',
          type: 'string',
          enum: ['text', 'name', 'email', 'textarea', 'phone', 'checkbox', 'inline', 'button', 'agreement'],
          required: true
        },
        {
          name: 'placeholder',
          type: 'string',
          showIf: `options.get('type') === 'text' || options.get('type') === 'phone'`
        },
        {
          name: 'required',
          type: 'boolean'
        },
        {
          name: 'errorMsg',
          type: 'string',
          showIf: `options.get('type') === 'text' || options.get('type') === 'phone'`
        },
        {
          name: 'gap',
          type: 'number',
          showIf: `options.get('type') === 'inline'`,
        },
        {
          name: 'variant',
          type: 'string',
          enum: ['secondary', 'primary', 'out-secondary', 'muted'],
          defaultValue: 'muted',
          showIf: `options.get('type') === 'button'`,
        },
        {
          name: 'title',
          type: 'string',
          showIf: `options.get('type') === 'checkbox' || options.get('type') === 'inline' || options.get('type') === 'button'`,
          required: true,
        },
        {
          name: 'title',
          type: 'string',
          showIf: `options.get('type') === 'inline'`,
          required: false,
        },
        {
          name: 'defaultChecked',
          type: 'boolean',
          showIf: `options.get('type') === 'checkbox'`,
        },
        {
          name: 'children',
          type: 'list',
          showIf: `options.get('type') === 'inline'`,
          subFields: [
            {
              name: 'name',
              type: 'string',
              required: true,
              defaultValue: false
            },
            {
              name: 'type',
              type: 'string',
              enum: ['text', 'name', 'email', 'textarea', 'phone', 'checkbox', 'inline'],
              required: true
            },
            {
              name: 'placeholder',
              type: 'string',
              showIf: `options.get('type') === 'text' || options.get('type') === 'phone'`
            },
            {
              name: 'required',
              type: 'boolean'
            },
            {
              name: 'defaultChecked',
              type: 'boolean',
              showIf: `options.get('type') === 'checkbox'`,
            },
            {
              name: 'errorMsg',
              type: 'string',
              showIf: `options.get('type') === 'text' || options.get('type') === 'phone'`
            },
            {
              name: 'title',
              type: 'string',
              showIf: `options.get('type') === 'checkbox'`,
              required: true,
            },
          ]
        },
      ]
    },
    {
      name: 'apiUrl',
      type: 'url',
    },
    {
      name: 'color',
      type: 'color'
    },
    {
      name: 'btnVariant',
      type: 'string',
      enum: ['secondary', 'primary', 'out-secondary', 'muted'],
      defaultValue: 'muted'
    },
    {
      name: 'btnText',
      type: 'text',
    }
  ]
})
