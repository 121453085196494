import {toast} from "react-toastify";


export const sendBid = async (url: string, data: any, setIsFetching: (val:boolean) => void, reset?: () => void) => {
  const onError = () => {
    toast.error('Ошибка! Повторите позже.')
    setIsFetching(false)
  }

  try {
    setIsFetching(true)
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
    if(response && response.status === 200){
      toast.success('Заявка принята!')
      setIsFetching(false)
      reset && reset()
    }
    else {
      onError()
    }
  }
  catch (reason) {
    onError()
  }
}

// export const sendBid = async (url: string, data: any, success?: () => void, error?: () => void) => {
//   try {
//     const response = await fetch(url, {
//       method: 'POST',
//       body: JSON.stringify(data),
//       headers: {
//         'Accept': 'application/json',
//         'Content-Type': 'application/json'
//       }
//     })
//     if(response && response.status === 200) success && success()
//     else error && error()
//   }
//   catch (reason) {
//     error && error()
//   }
// }