import {useForm, FormProvider} from "react-hook-form";
import React, {createRef, FC, forwardRef, ReactNode, useImperativeHandle} from "react";
import {Box} from "theme-ui";
import {ReactRef} from "use-callback-ref/dist/es5/types";

interface FormProps {
  children: ReactNode
  onSubmit: (data: any) => void
  defaultValues?: any
  ref?: any
}

export interface FormRef {
  reset: () => void
}

export const Form: FC<FormProps> = forwardRef<FormRef, FormProps>((props,ref) => {
  const form =  useForm({defaultValues: props.defaultValues || {}});
  const { handleSubmit, reset } = form
  const { onSubmit, children } = props

  useImperativeHandle(ref, () => ({
    reset: () => reset()
  }));

  return (
      <FormProvider {...form} >
        <Box as="form" onSubmit={handleSubmit((data) => onSubmit(data))}>
          {children}
        </Box>
      </FormProvider>
  )
})
