import { Builder } from '@builder.io/react'
import {useEffect, useState} from "react";
import dynamic from "next/dynamic";

const LazyAnimatedNumber = dynamic(async () => {
  return (await import('./index')).default
})

interface Props {
  value: number
  className: string
  style: any
}

Builder.registerComponent(({ value, className, style}:Props) => {
  const [val, setVal] = useState(0)

  useEffect(() => {
    setVal(value)
  }, [])

  return (
      <LazyAnimatedNumber value={val} className={className} style={style} />
  )
}, {
  name: 'AnimatedNumber',
  inputs: [
      {
        name: 'value',
        type: 'number'
      },
  ],
  description: 'Анимация цифры',
})