import {Builder, BuilderBlocks, noWrap} from "@builder.io/react"
import React, {FC, ReactNode, useContext, useRef} from "react"
import {Box} from "theme-ui"
import AccordionProvider from "./AccordionProvider"
import AccordionContext from "./AccordionContext"



interface IAccordion {
  title: Array<any>
  detail: Array<any>
}

interface IAccordionBuilder{
  items: Array<IAccordion>
  backgroundColor: string
  attributes: any
}

interface IContextAwareToggle {
  children: ReactNode
  eventKey: number
}

const ContextAwareToggle:FC<IContextAwareToggle> = ({ children, eventKey }) => {
  const { state, toggleAccordion } = useContext(AccordionContext);
  const isCurrentEventKey = state === eventKey;

  return (
      <Box
          className={[ "accordion-show", (isCurrentEventKey && 'accordion-active')].join(' ')}
          onClick={() => toggleAccordion(eventKey)}
      >
        {children}
      </Box>
  );
}

const ContextCollapse:FC<IContextAwareToggle> = ({ children, eventKey }) => {
  const { state } = useContext(AccordionContext);
  const content = useRef<HTMLDivElement>(null);
  const isActive = state === eventKey;

  return (
      <Box
          ref={content}
          sx={{
            maxHeight: isActive ? `${content?.current?.scrollHeight}px`: '0px',
            overflow: 'hidden',
            transition: 'max-height 0.6s ease'
          }}
      >
        {children}
      </Box>
  );
}


const AccordionBuilder:FC = (props: any) => {
  const { items, backgroundColor, attributes: { key, ...otherAtt}}: IAccordionBuilder = props

  return (
      <Box key={key} {...otherAtt} className={(props.className || '')}>
        <AccordionProvider>
          {items.map((item, index) => {
            return (
                <Box key={`accordion-item-${index}`} sx={{display: 'flex', flexDirection: 'column', backgroundColor}}>
                  <ContextAwareToggle eventKey={index}>
                    <BuilderBlocks blocks={item.title} />
                  </ContextAwareToggle>
                  <ContextCollapse eventKey={index}>
                    <BuilderBlocks blocks={item.detail} />
                  </ContextCollapse>
                </Box>
            )
          })}
        </AccordionProvider>
      </Box>
  )
}

const AccordionBuilderChildren = noWrap(AccordionBuilder)


const defaultValue = {
      title: [
        {
          "@type": "@builder.io/sdk:Element",
          layerName: "Accordion item title",
          responsiveStyles: {
            "large": {
              "display": "flex",
              "flexDirection": "row",
              "alignItems": "center",
              "justifyContent": "space-between",
              "paddingLeft": "20px",
              "paddingRight": "20px"
            }
          },
          children: [
            {
              "@type": "@builder.io/sdk:Element",
              component: {
                name: "Text",
                options: {
                  text: "Accordion item title"
                }
              }
            },
            {
              "@type": "@builder.io/sdk:Element",
              "class": "accordion-icon",
              children: [
                {
                  "@type": "@builder.io/sdk:Element",
                  component: {
                    name: "Text",
                    options: {
                      text: "+<br>"
                    }
                  },
                  responsiveStyles: {
                    "large": {
                      "display": "flex",
                      "flexDirection": "column",
                      "position": "relative",
                      "flexShrink": "0",
                      "boxSizing": "border-box",
                      "marginLeft": "0px",
                      "lineHeight": "35px",
                      "height": "auto",
                      "paddingLeft": "0px",
                      "paddingRight": "0px",
                      "paddingTop": "0px",
                      "paddingBottom": "6px",
                      "fontSize": "38px",
                      "fontWeight": "300",
                      "color": "rgba(42, 36, 33, 1)",
                      "fontFamily": "system-ui, sans-serif"
                    }
                  }
                }
              ],
              responsiveStyles: {
                "large": {
                  "position": "relative",
                  "flexShrink": "0",
                  "boxSizing": "border-box",
                  "marginTop": "4px",
                  "display": "flex",
                  "flexDirection": "row",
                  "textAlign": "center",
                  "width": "32px",
                  "height": "32px",
                  "backgroundColor": "rgba(228, 143, 82, 1)",
                  "borderRadius": "16px",
                  "justifyContent": "center",
                  "alignItems": "center",
                  "marginBottom": "4px"
                }
              }
            },
          ]
        }
      ],
      detail: [
        {
          "@type": "@builder.io/sdk:Element",
          layerName: "Accordion item detail",
          responsiveStyles: {
            "large": {
              "paddingLeft": "20px",
              "paddingRight": "20px",
              "paddingBottom": "10px",
              "fontSize": "14px"
            }
          },
          children: [
            {
              "@type": "@builder.io/sdk:Element",
              component: {
                name: "Text",
                options: {
                  text: "Accordion item detail"
                }
              }
            }
          ]
        }
      ]
    }


Builder.registerComponent(AccordionBuilderChildren, {
  name: 'Accordion',
  defaultStyles: {
    "display": "flex",
    "flexDirection": "column",
    "position": "relative",
    "flexShrink": "0",
    "boxSizing": "border-box",
    "marginTop": "20px",
    "gap": "10px",
    "color": "rgba(42, 36, 33, 1)",
    "fontFamily": "Montserrat, sans-serif",
    "fontSize": "18px",
    "fontWeight": "300"
  },
  inputs: [
    {
      name: "items",
      type: "list",
      broadcast: true,
      bubble: true,
      subFields: [
        {
          name: "title",
          type: "uiBlocks",
          hideFromUI: true,
          defaultValue: [
            {
              "@type": "@builder.io/sdk:Element",
              layerName: "Accordion item title",
              responsiveStyles: {
                "large": {
                  "display": "flex",
                  "flexDirection": "row",
                  "alignItems": "center",
                  "justifyContent": "space-between"
                }
              },
              children: [
                {
                  "@type": "@builder.io/sdk:Element",
                  responsiveStyles: {
                    "large": {
                      "textAlign": "left",
                      "display": "flex",
                      "flexDirection": "column"
                    }
                  },
                  component: {
                    name: "Text",
                    options: {
                      text: "I am an accordion title. Click me!"
                    }
                  }
                },
                {
                  "@type": "@builder.io/sdk:Element",
                  "children": [
                    {
                      "@type": "@builder.io/sdk:Element",
                      "component": {
                        "name": "Text",
                        "options": {
                          "text": "+<br>"
                        }
                      },
                      "responsiveStyles": {
                        "large": {
                          "display": "flex",
                          "flexDirection": "column",
                          "position": "relative",
                          "flexShrink": "0",
                          "boxSizing": "border-box",
                          "marginLeft": "0px",
                          "lineHeight": "35px",
                          "height": "auto",
                          "paddingLeft": "0px",
                          "paddingRight": "0px",
                          "paddingTop": "0px",
                          "paddingBottom": "6px",
                          "fontSize": "38px",
                          "fontWeight": "300",
                          "color": "rgba(42, 36, 33, 1)"
                        }
                      }
                    }
                  ],
                  "responsiveStyles": {
                    "large": {
                      "position": "relative",
                      "flexShrink": "1",
                      "boxSizing": "border-box",
                      "marginTop": "8px",
                      "display": "flex",
                      "flexDirection": "row",
                      "textAlign": "center",
                      "width": "32px",
                      "height": "32px",
                      "backgroundColor": "rgba(228, 143, 82, 1)",
                      "borderRadius": "16px",
                      "justifyContent": "center",
                      "alignItems": "center",
                      "marginBottom": "8px"
                    }
                  }
                }
              ]
            }
          ]
        },
        {
          name: "detail",
          type: "uiBlocks",
          hideFromUI: true,
          defaultValue: [
            {
              "@type": "@builder.io/sdk:Element",
              layerName: "Accordion item detail",
              responsiveStyles: {
                "large": {
                  "position": "relative",
                  "display": "flex",
                  "alignItems": "stretch",
                  "flexDirection": "column",
                  "marginTop": "10px",
                  "paddingBottom": "10px"
                }
              },
              children: [
                {
                  "@type": "@builder.io/sdk:Element",
                  responsiveStyles: {
                    "large": {
                      "paddingTop": "50px",
                      "textAlign": "left",
                      "display": "flex",
                      "flexDirection": "column",
                      "paddingBottom": "50px"
                    }
                  },
                  component: {
                    name: "Text",
                    options: {
                      text: "I am an accordion detail, hello!"
                    }
                  }
                }
              ]
            }
          ]
        }
      ],
      defaultValue: [
        defaultValue,
        defaultValue,
        defaultValue
      ],
    },
    {
      name: 'backgroundColor',
      type: 'color'
    }
  ],
})
