// @ts-nocheck
import {Box, BoxOwnProps, Input, jsx} from "theme-ui";
import {Label} from "@theme-ui/components";
import React, {FC} from "react";
import {Controller, useFormContext} from "react-hook-form";
import {isValidPhoneNumber} from "react-phone-number-input";
import PhoneInput from "react-phone-number-input/react-hook-form-input";
import {Validation} from "@components/common/Form/helpers";


interface InputFieldFormProps extends BoxOwnProps{
  name: string
  errorMsg?: string
  required?: boolean
  variant: string
  placeholder?: string
  type?: string
}

export const InputFieldForm: FC<InputFieldFormProps> = (props) => {
  const {errorMsg, name, type, required, ...inputProps} = props

  return (
      <Controller
          name={name}
          rules={{
            validate: (value) => Validation(value, type, type === 'name' && 3)}}
          render={({ field: { onChange, value }, fieldState: {error}, }) => (
              <Box sx={{flexGrow: 1, position: "relative"}}>
                <Input name={name} onChange={onChange} value={value} {...inputProps} />
                {error &&
                <Label sx={{position: 'absolute', bottom: 0, fontSize: '12px', color: 'red'}} htmlFor={name}>
                  {errorMsg}
                </Label>
                }
              </Box>
          )}
      />
  )
}