import {isValidPhoneNumber} from "react-phone-number-input";

const isValidEmail = (value: string): boolean => {
  return !!(value && value.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g));
}

const isValidLength = (value: string, minLength?: number): boolean => {
  if(minLength) {
    return value ? value.length >= minLength : false
  }
  else return true
}

export const Validation = (value: string, type: string, minLength?: number): boolean => {
  switch (type) {
    case 'phone': return isValidPhoneNumber(value || '')
    case 'email': return isValidEmail(value || '')
    case 'name': return isValidLength(value || '', minLength)
    default: return true
  }
}