import {Builder, noWrap} from "@builder.io/react";
import dynamic from "next/dynamic";

const LazyProductsGrid = dynamic(async () => {
  return (await import('./ProductsGrid')).ProductsGrid
})

const ProductsGridBuilderChildren = noWrap(LazyProductsGrid)

Builder.registerComponent(ProductsGridBuilderChildren, {
  name: 'ProductsGrid',
})
