import {FC} from "react";

interface IconHeartProps {
  width: string
}

export const IconHeart:FC<IconHeartProps> = ({ width }) => {

  return (
      <svg viewBox="0 0 47 37" fill="none" width={width}>
        <path d="M36.4294 8.0792H36.4286C36.4327 7.98919 36.436 7.89836 36.436 7.80752C36.436 4.14729 33.3433 0.975586 29.5275 0.975586C26.7382 0.975586 24.3367 2.76765 23.2454 5.05151H23.2438C22.1533 2.76847 19.7518 0.975586 16.9617 0.975586C13.1467 0.975586 10.0532 4.14811 10.0532 7.80752C10.0532 7.89836 10.0565 7.98919 10.0606 8.0792H10.0598C10.0598 8.0792 9.66948 12.4268 14.5176 17.1737C16.7215 19.3782 19.1869 21.4722 23.2438 24.7642C27.3007 21.473 29.7661 19.3782 31.97 17.1737C36.8181 12.4268 36.4278 8.0792 36.4278 8.0792H36.4294Z" stroke="" strokeLinecap="round" strokeLinejoin="round"></path>
        <path d="M6.36495 24.6538C6.36495 24.6538 4.93019 21.833 4.00731 21.0774C3.08527 20.3219 3.4946 19.2639 5.18613 19.5163C6.87683 19.7679 9.18277 22.7402 10.3107 24.1006C11.4379 25.461 12.7192 25.5618 12.7192 25.5618C16.8701 26.1666 18.0881 26.455 20.3599 29.9814C23.2802 34.5141 21.0777 36.6309 18.0031 36.4293C14.9285 36.2277 13.2886 34.7165 13.2886 34.7165C13.2886 34.7165 8.92684 32.0097 7.23614 31.2541C5.54545 30.4985 1.80391 22.791 0.932717 20.7259C0.0615254 18.6607 0.727633 11.3065 1.9573 11.3565C3.18698 11.4064 3.85308 12.112 3.85308 14.782C3.85308 17.4519 4.10902 18.7107 4.10902 18.7107" stroke="" strokeLinecap="round" strokeLinejoin="round"></path>
        <path d="M40.1244 24.6538C40.1244 24.6538 41.5592 21.833 42.4813 21.0774C43.4034 20.3219 42.9941 19.2639 41.3025 19.5163C39.6117 19.7679 37.3057 22.7402 36.1777 24.1006C35.0505 25.461 33.7691 25.5618 33.7691 25.5618C29.618 26.1666 28.4 26.455 26.1281 29.9814C23.2076 34.5141 25.4103 36.6309 28.485 36.4293C31.5597 36.2277 33.1996 34.7165 33.1996 34.7165C33.1996 34.7165 37.5616 32.0097 39.2524 31.2541C40.9431 30.4985 44.6848 22.791 45.5552 20.7259C46.4264 18.6607 45.7603 11.3065 44.5306 11.3565C43.3009 11.4064 42.6347 12.112 42.6347 14.782C42.6347 17.4519 42.3788 18.7107 42.3788 18.7107" stroke="" strokeLinecap="round" strokeLinejoin="round"></path>
      </svg>
  )
}