import dynamic from "next/dynamic";
import {Builder} from "@builder.io/react";


const LazyGrid = dynamic(async () => {
  return (await import('./Grid')).Grid
})

Builder.registerComponent(LazyGrid, {
  name: 'Grid',
  canHaveChildren: true,
  inputs: [
    {
      name: 'gap',
      type: 'string',
    },
    {
      name: 'columns',
      type: 'string',
    },
    {
      name: 'width',
      type: 'string'
    },
    {
      name: 'repeat',
      type: 'string',
      enum: ['fill', 'fit']
    }
  ]
})
