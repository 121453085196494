import {FC, useContext} from "react";
import {Box, Button} from "theme-ui";
import {Builder, noWrap} from "@builder.io/react";
import ModalContext from "@components/modals/ModalContext";

interface Props {
  text: string
  attributes: any
}

const ModalButton: FC<Props> = (props) => {
  const { setShow } = useContext(ModalContext);

  const { key, ...otherAttr } = props.attributes

  // console.log('ModalButton', props)
  return(
      <Button
          key={key}
          {...otherAttr}
          className={props.attributes.className}
          onClick={setShow}
      >
        {props.text}
      </Button>
  )
}

const ModalButtonBuilderChildren = noWrap(ModalButton)

Builder.registerComponent(ModalButtonBuilderChildren, {
  name: 'ModalButton',
  image: "https://cdn.builder.io/api/v1/image/assets%2FIsxPKMo2gPRRKeakUztj1D6uqed2%2F81a15681c3e74df09677dfc57a615b13",
  defaultStyles: {
    "appearance": "none",
    "paddingTop": "15px",
    "paddingBottom": "15px",
    "paddingLeft": "25px",
    "paddingRight": "25px",
    "backgroundColor": "black",
    "color": "white",
    "borderRadius": "4px",
    "textAlign": "center",
    "cursor": "pointer"
  },
  inputs: [
    {
      name: "text",
      type: "text",
      defaultValue: "Click me!",
      bubble: true
    },
  ],
  static: true,
  noWrap: true,
  type: "react",
  hooks: {}
})
