/** @jsxRuntime classic */
/** @jsx jsx */
import React, {useState} from 'react'
import {ThemeProvider, jsx, Box} from 'theme-ui'
import dynamic from 'next/dynamic'
import { Button } from 'theme-ui'
import { ManagedUIContext } from '@components/common/context'
import Head from '@components/common/Head'
import { useAcceptCookies } from '@lib/hooks/useAcceptCookies'
import { BuilderContent, Builder } from '@builder.io/react'
import themesMap from '@config/theme'
import seoConfig from '@config/seo.json'
import NoSSR from './NoSSR'
import Header from "@components/common/Navbar"
import ModalProvider from "@components/modals/ModalProvider"
import {Footer} from "@components/common/Footer"
import {DirectionsCard} from "../../blocks/DirectionsCard/DirectionsCard";
import { GoogleTagManager } from '@next/third-parties/google'
import ENV from "@config/env"


const FeatureBar = dynamic( () => import('@components/common/FeatureBar'), {ssr: false})
const ToastContainer = dynamic(async () => (await import('react-toastify')).ToastContainer, {ssr: false})
const Modal = dynamic(async () => (await import('@components/modals/Modal')).Modal, {ssr: false})

const Layout: React.FC<{ pageProps: any; children: React.ReactNode, fonts: any }> = ({
  children,
  pageProps, fonts
}) => {
  const builderTheme = pageProps.theme

  // console.log('Layout', pageProps)

  return (
      <>
        <BuilderContent content={builderTheme} modelName="theme">
          {(data, loading) => {
            if (loading && !builderTheme) {
              return 'loading ...'
            }
            const siteSettings = data?.siteSettings
            const colorOverrides = data?.colorOverrides
            const siteSeoInfo = data?.siteInformation

            return (
                <ManagedUIContext key={data?.id} siteSettings={siteSettings}>
                  <Head seoInfo={siteSeoInfo || seoConfig} />
                  <InnerLayout
                      themeName={data?.theme || 'base'}
                      fonts={fonts}
                      colorOverrides={colorOverrides}
                      consultantIsOrange={pageProps.page?.data?.consultantIsOrange || false}
                  >
                    {children}
                  </InnerLayout>
                </ManagedUIContext>
            )
          }}
        </BuilderContent>
      </>
  )
}

const InnerLayout: React.FC<{
  themeName: string
  children: React.ReactNode
  colorOverrides?: {
    text?: string
    background?: string
    primary?: string
    secondary?: string
    muted?: string
  }
  fonts: any
  consultantIsOrange: boolean
}> = ({ themeName, children, colorOverrides, fonts, consultantIsOrange }) => {
  const theme = {
    ...themesMap[themeName],
    colors: {
      ...themesMap[themeName].colors,
      ...colorOverrides,
    },
    fonts: {
      ...themesMap[themeName].fonts,
      ...fonts
    },
    buttons: {
      primary: {
        color: 'secondary',
        bg: 'primary',
        transition: 'all 0.5s cubic-bezier(.37,.01,0,.98)',
        cursor: 'pointer',
        '&:hover': {
          color: 'primary',
          bg: 'secondary',
        }
      },
      secondary: {
        color: 'primary',
        bg: 'secondary',
        borderRadius: 0,
        border: '2px solid',
        borderColor: 'secondary',
        transition: 'all 0.5s cubic-bezier(.37,.01,0,.98)',
        cursor: 'pointer',
      },
      muted: {
        color: 'secondary',
        bg: 'muted',
        borderRadius: 0,
        transition: 'all 0.5s cubic-bezier(.37,.01,0,.98)',
        cursor: 'pointer',
        '&:hover': {
          bg: 'secondary',
          color: 'muted'
        }
      },
      mutedActive: {
        color: 'muted',
        bg: 'secondary',
        borderRadius: 0,
        transition: 'all 0.5s cubic-bezier(.37,.01,0,.98)',
      },
      "out-secondary": {
        color: 'secondary',
        border: '2px solid',
        borderColor: 'secondary',
        borderRadius: 0,
        transition: 'all 0.5s cubic-bezier(.37,.01,0,.98)',
        cursor: 'pointer',
        '&:hover': {
          bg: 'secondary',
          color: 'primary'
        }
      },
      "category": {
        color: 'secondary',
        border: '2px solid',
        borderColor: 'secondary',
        borderRadius: 0,
        cursor: 'pointer',
        transition: 'all 0.5s cubic-bezier(.37,.01,0,.98)',
        '&:hover': {
          bg: 'muted',
          color: 'primary'
        }
      },
      "category-active": {
        color: 'primary',
        border: '2px solid',
        borderColor: 'secondary',
        borderRadius: 0,
        bg: 'muted',
        cursor: 'pointer',
        transition: 'all 0.5s cubic-bezier(.37,.01,0,.98)',
      },
      "palette": {
        flexShrink: 0,
        padding: 0,
        borderRadius: 15,
        transition: 'all 0.5s cubic-bezier(.37,.01,0,.98)',
        cursor: 'pointer',
        '&:hover': {
          border: '2px solid',
          borderColor: 'white',
        }
      },
      "palette-active": {
        flexShrink: 0,
        padding: 0,
        border: '2px solid',
        borderColor: 'white',
        borderRadius: 15,
        cursor: 'pointer',
      },
      send: {
        cursor: 'pointer',
        color: 'muted',
        transition: 'all 0.5s cubic-bezier(.37,.01,0,.98)',
        '&:hover': {
          color: 'secondary'
        },
        '&:disabled': {
          color: 'gray',
          cursor: 'default',
        }
      },
      'btn-footer': {
        bg: 'secondary',
        color: 'primary',
        cursor: 'pointer',
        '&:hover': {
          color: '#a9a8a8',
          stroke: '#a9a8a8'
        }
      }
    },
    links: {
      secondary: {
        color: 'secondary',
        bg: 'none',
        border: 0,
        '&:hover': {
          color: 'muted'
        }
      },
      underline: {
        textDecoration: 'underline !important'
      },
      'nav-link': {
        color: 'primary',
        display: 'block',
        textDecoration: 'none',
        bg: 'none',
        border: 0,
        fontSize: '24px'
      },
      'nav-link-footer': {
        fontWeight: 300,
        color: 'secondary',
        display: 'block',
        textDecoration: 'none',
        bg: 'none',
        border: 0,
      },
    },
    forms: {
      inputs: {
        secondary: {
          border: 'none',
          borderBottom: '1px solid',
          borderColor: 'secondary',
          borderRadius: 0,
          color: 'muted',
          outline: 'none',
          resize: 'none',
          'WebkitTextFillColor': colorOverrides?.muted,
          '&:focus-visible': {
            color: 'secondary',
          },
        }
      },
      checkbox: {
        secondary: {
          borderColor: 'secondary',
          outline: 'none',
          color: 'secondary',
          fill: 'secondary',
          backgroundColor: 'transparent !important',
        }
      },
      text: {
        borderRadius: 0,
        borderColor: 'secondary',
        resize: 'none',
        outline: 'none',
        '&:focus-visible': {
          color: 'secondary',
        },
      }
    },
    boxes: {
      consultant: {
        bg: 'background',
        fill: 'secondary',
        '&:hover': {
          bg: 'muted',
          fill: 'primary'
        }
      },
      'consultant-secondary': {
        bg: 'muted',
        fill: 'secondary',
        '&:hover': {
          bg: 'secondary',
          fill: 'primary'
        }
      },
      'directions-card-text': {
        transition: 'all 0.5s',
        paddingTop: '100px'
      }
    },
    styles: {
      root: {
        ...themesMap[themeName].styles.root,
        a: {
          color: 'muted',
          fontFamily: 'Montserrat, sans-serif',
          transition: 'color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out',
        },
      },
    }
  }

  // console.log('theme', theme)

  const { acceptedCookies, onAcceptCookies } = useAcceptCookies()
  return (
    <ThemeProvider theme={theme}>
      <ModalProvider>
        <Header consultantIsOrange={consultantIsOrange} />
        <main>{children}</main>
        <Footer />
        <NoSSR>
          <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={true}
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnFocusLoss={false}
              draggable
              pauseOnHover={false}
              theme="colored"
          />
          <FeatureBar
              title="Мы используем cookie, чтобы сделать сайт удобнее"
              hide={Builder.isEditing ? true : acceptedCookies}
              action={
                <Button variant="primary" onClick={() => onAcceptCookies()}>Хорошо, не показывать больше</Button>
              }
          />
          <Modal />
          {!ENV.isDev && <GoogleTagManager gtmId='GTM-T7PS7C5F' /> }
        </NoSSR>
      </ModalProvider>
    </ThemeProvider>
  )
}

export default Layout
